import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "自分たちにも優しい”デザインとは？",
  "date": "2018-01-31T07:11:00.000Z",
  "slug": "entry/2018/01/31/161100",
  "tags": ["medley"],
  "hero": "./2018_01_31.png",
  "heroAlt": "優しいデザイン"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。最近白髪が目立つようになりました。最初は蛍光灯の反射かな？とおもっていましたが実はそうじゃないらしいイケメン担当デザイナーの小山です。`}</p>
    <p>{`私が担当しているジョブメドレーではサービスの改善をいくつも重ねますがその結果、全体の統一感が薄くなりデザインの改善をすることがあります。`}</p>
    <p>{`こういった場合、もちろんユーザーの使いやすさを前提にリニューアルを考えますが、`}<strong parentName="p">{`自分たち（開発者）にも使いやすいデザイン`}</strong>{`はどういう姿か追いながら取り組むように心がけるようにしてみることにしました。`}</p>
    <p>{`この試みの背景にはサービスの`}<strong parentName="p">{`プロダクト基本理念`}</strong>{`が関係しています。このエントリでは、先般行ったモバイル向けの求職者画面のデザインリニューアルを通して、プロダクト基本理念に基づいた「自分たちにも使いやすいデザイン」を実現した話をご紹介いたします。`}</p>
    <h1>{`開発工数を増大させるデザイン`}</h1>
    <p>{`まず始めにジョブメドレーの求職者画面についてです。これは医療従事者向けの求人を探しているユーザー『求職者』のための画面で、求人の絞り込む検索、求人の詳細情報、職種の情報を発信するブログ、サービスのコンセプト紹介など多彩な情報で構成されています。`}</p>
    <p>{`メドレーが運営しているサービスの中で最も歴史があり、求職者の希望に見合った求人をスマートに探すことができるように、リニューアルや機能拡充を繰り返しおこなってきました。おかげさまで毎年安定的な成長をとげています。`}</p>
    <undefined><img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180130/20180130202329.png",
        "alt": "20180130202329.png"
      }}></img>
      <span {...{
        "style": {
          "color": "#999999"
        }
      }}>{`求職者画面の一部`}</span></undefined>
    <p>{`ただその一方で、リニューアルに対応しきれなかったページも数多くあります。未対応のページは時期を改めて対応するはずでしたが、開発リソースの事情で全て対応できている状態ではありません。`}</p>
    <p>{`そうした未対応がリニューアルごとに積み重なり、数世代分のデザインパーツが並行運用され、開発の作業効率がなかなか向上できない状況でした。`}</p>
    <p>{`たとえばボタンやアイコンが同じであったとしても挙動や意味付けにズレが生じていたり、使われているはずがないデザインパーツが全く別の使われ方で存在していたり、全体の把握が追いついていない状況が所々ありました。`}</p>
    <p>{`これは求職者にとって使い勝手が複雑になる上に、似たようなデザインパーツが増殖されたり誤った使い方をすることで、デザインのルールが曖昧になりエンジニアの作業工数が肥大化していきます。もちろんデザイナーの工数もです。`}</p>
    <p>{`今回のデザインリニューアルの発端は、ユーザーのために更に使いやすさの改善と、用途が曖昧なデザインパーツの並行運用がもたらす開発上の問題を解決したいということもありました。`}</p>
    <h1>{`プロダクト基本理念に立ち返る`}</h1>
    <p>{`問題を解決するためにどういう方向性が良いか検討するため、まずプロダクト理念まで立ち返ってみました。`}</p>
    <p>{`ジョブメドレーのプロダクト理念は、各部署が業務を行うための行動指針のようなものです。
プロダクトを一定の品質を保つため、求職者の求職活動を支えるキャリアサポート、事業所の効果的な求人掲載を提案するカスタマーサクセスやクリエイションチーム、これから求人を掲載したい事業所をサポートするセールスチーム、そして開発チームがこのプロダクト理念をもとに動いています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180130/20180130202400.jpg",
      "alt": "20180130202400.jpg"
    }}></img>
    <p>{`求人を探すユーザー『求職者』と求人を出すユーザー『事業者』の双方が満足のいく意思決定をするためのこれら３つの理念を核としています。また、その理念を通して実現する`}<a parentName="p" {...{
        "href": "https://job-medley.com/mission/"
      }}>{`サービスのミッション`}</a>{`の達成に向かってメンバーは業務を行なっています。デザインパーツもこの理念をもとに制作されています。`}</p>
    <p>{`ただ前述の通り、求職者画面が理念を守れる万全な状況にあるかというと、そうではありませんでした。必ずしも完璧なデザインが世の中にないように、ジョブメドレーももっと良くしていけるポイントはデザイン面で数多くあり、今回のリニューアルでは、そうした課題解決を目指しました。`}</p>
    <p>{`さらに進めるにあたり、長期的にプロダクト理念に基づいた開発・デザインが行えるよう「求職者と開発の両方に働きかけられる環境」の整備を行うことにしました。`}</p>
    <h2>{`求職活動だけに集中できる環境`}</h2>
    <p>{`満足のいく意思決定を行う環境は、情報を読むことに悩むよう環境ではなくて、読んだ情報をじっくり考えられる環境だと私は考えています。`}</p>
    <p>{`これまでのリニューアルで生まれた数世代ぶんの負債のために、字が読みにくいだとか、ボタン押したらどうなるかわからないだとか、そういった本質的ではない部分でつまづかない環境を作りました。`}</p>
    <h2>{`デザイナーがいなくても開発できる環境`}</h2>
    <p>{`いくら見栄えがよくユーザーにとって使い勝手が良かったとしても、それを運用するために開発のコストがかかるのであれば良い UI とは言えません。`}</p>
    <p>{`使い勝手を維持するために開発を逼迫させ本来コストをかけるべきところに注力できずに別の部分の使い勝手を落とし、結果的にユーザーの不利益や不信感に繋がるかもしれないからです。これは極端な考えかもしれませんが、起こる可能性としては低くはないはずです。`}</p>
    <p>{`それを解決する方法の１つとしてプロジェクトの関係者が把握できる内容にまでデザインを単純化させることなのではと私は考えています。デザイナーしか把握できないものではなく、むしろデザイナーが関わらなくても把握できるものを目指しました。`}</p>
    <h1>{`エンジニアだけで作れるデザイン`}</h1>
    <h2>{`扱いやすいデザインとその意図`}</h2>
    <p>{`今回できたデザインは「普通」です。なるべく特殊はことは避け、デザイナー以外でもわかるデザインを目指しました。またデザインの意図もできる限りシンプルにまとめることに注力しました。`}</p>
    <p>{`理路整然とした意図でも、それが複雑だとつくった本人しか扱えないものになります。なので聞きなれないワードや表現は控え、真に守らなくてはいけないことを抽出しました。またコンポーネントも限界まで削ぎ落とし最適化しました。`}</p>
    <undefined><img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180130/20180130202419.jpg",
        "alt": "20180130202419.jpg"
      }}></img>
      <span {...{
        "style": {
          "color": "#999999"
        }
      }}>{`デザインの意図を明文化`}</span></undefined>
    <undefined><img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180130/20180130202442.jpg",
        "alt": "20180130202442.jpg"
      }}></img>
      <span {...{
        "style": {
          "color": "#999999"
        }
      }}>{`把握しやすい数に色を減らす　左がリニューアル前：右がリニューアル後`}</span></undefined>
    <p>{`この過程でプロジェクトでうれしい場面がありました。それはエンジニアさんから新しいデザインがあがったこと。`}</p>
    <p>{`デザインパーツが足りず追加制作していたところ、既にエンジニアさんがデザイントーンを参考に足りないパーツを製作してくれていました。しかも私のデザインよりも良いもので。。。複雑な心境になりましたが、それぐらいデザインが扱いやすくなっているということでもあるのかなと`}<del parentName="p">{`自分に言い聞かせました`}</del>{`ポジティブに考えるようにしました。`}</p>
    <undefined><img {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180130/20180130202502.jpg",
        "alt": "20180130202502.jpg"
      }}></img>
      <span {...{
        "style": {
          "color": "#999999"
        }
      }}>{`左：私のデザイン　右：エンジニアのデザイン　どう見ても左より右が周囲と喧嘩してない。左はしゃしゃり出る`}</span></undefined>
    <p>{`今回のプロジェクトは UI を綺麗にすることだけじゃなく、それ自体を関係者が扱いやすいものに改善する目的でもあったので、この出来事は当初考えていたこと以上の結果で良かったと思っています。`}</p>
    <p>{`この要因は単純にルールが簡単で覚えやすかったことなのかもしれません。そうした覚えやすいデザインは学習コストが軽く、まわりめぐってユーザーにも扱いやすいものなると考えています。`}</p>
    <h2>{`デザインを維持できるコンポーネントの運用`}</h2>
    <p>{`昨今ではデザインシステムやスタイルガイドの普及により、デザインパーツ、コンポーネントなどを運用することはさほど特別なことではないと思います。`}</p>
    <p>{`今回のリニューアルでは整備した環境を維持するためにスタンダードな運用にも着手します。こうした取り組みは以前にも行なっていたものの開発チームに明文化されていなかったので、改善を繰りかえし動かしていきたいと思います。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回のリニューアルはバラバラだったデザイントーンに統一感を持たせることでユーザーに使いやすさを担保する、その過程で自分たちにも使いやすいデザインを目指しました。`}</p>
    <p>{`一般的にはリニューアルではユーザーを中心したものが多いかもしれません。今回はその中に自分たち（開発者）も加味して考え、サービスのミッションの達成のためのプロダクト基本理念を支えられる環境作りを目指しました。`}</p>
    <p>{`デザインの役割は物事を綺麗にするだけではなく、理念のような根っこの部分に立ち返れる機会や根っこそのものを具現化させる役割を担っていると考えています。とはいえ今回はその第一歩。今後は更に運用方法やコンテンツ設計や導線設計に磨きをかける必要があります。`}</p>
    <p>{`また進捗があり次第ご報告できればと思います。ここまで読んでいただきありがとうございました。`}</p>
    <h1>{`最後に`}</h1>
    <p>{`メドレーでは、医療介護の求人サイト「ジョブメドレー」、医師たちがつくるオンライン医療事典「MEDLEY」、口コミで探せる介護施設の検索サイト「介護のほんね」、オンライン診療アプリ「CLINICS」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      